<template>
  <Popup
    closeable
    close-on-popstate
    close-icon="close"
    position="bottom"
    :safe-area-inset-bottom="true"
    v-model:show="show"
    :round="true"
    :style="{ height: '70%' }"
  >
    <div class="popup">
      <div class="popup-title">
        信息确认
      </div>
      <!-- 姓名 -->
      <div class="popup-p flex align-content">
        <span class="m-f-28 m-c-666 span1">姓名</span>
        <input type="text" v-model="cardInfo.realName" class="span1 m-f-28" />
        <!-- <span class="m-f-28">{{props.cardInfo.realName}}</span> -->
      </div>
      <!-- 身份证号码 -->
      <div class="popup-p flex align-content">
        <span class="m-f-28 m-c-666 span1">身份证号码</span>
        <span class="m-f-28">{{cardInfo.idCardNumber}}</span>
      </div>
      <!-- 身份证有效期 -->
      <div class="popup-p flex align-content">
        <span class="m-f-28 m-c-666 span1">身份证有效期</span>
        <span class="m-f-28">{{cardInfo.validDate}}</span>
      </div>
      <!-- 身份证 -->
      <div class="popup-img flex align-content">
        <div class="span1">
          <img :src="props.frontImgPathParams.watermarkUrl" alt="" srcset="">
        </div>
        <div class="span1">
          <img :src="props.reverseImgPath.watermarkUrl" alt="" srcset="">
        </div>
      </div>
      <!-- 协议 -->
      <div class="popup-protocol align-content flex">
        <div class="protocol-check"
          :class="{'check': selectStatus}"
          @click="onselect"></div>
        <div class="m-f-24 span1">
          我已阅读并同意<span class="hightlight-fontColor" @click="jump('https://pdf.dingxiangzu.com/MODEL/h5/UserInformationAuthorizationLetter.pdf')">《用户信息授权书》</span>
        </div>
      </div>
      <!-- 确定 -->
      <Button title="确认" className="submit m-f-36" @submit="onauthRealName" />
    </div>
  </Popup>
</template>
<script setup>
import { Popup, Toast } from 'vant';
import { ref, defineExpose, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Button from '@com/Button.vue';

import API from '@/config/api';
import { verifiedJump } from '@/utils/common';

/** props */
const props = defineProps({
  frontImgPathParams: Object,
  reverseImgPath: Object,
});
/** 变量 */
const router = useRouter();
const show = ref(false);
const store = useStore();
// 协议选中
const selectStatus = ref(false);
const cardInfo = ref({});

/** 函数 */
// const onshow = () => {
//   show.value = true;
// };
const onshow = async () => {

  Toast.loading({
    message: '请稍等...',
    forbidClick: true,
    duration: 0,
  });

  const data = await API.ocrScan({
    frontImgPath: props.frontImgPathParams.originUrl,
    reverseImgPath: props.reverseImgPath.originUrl,
  });
  show.value = true;
  cardInfo.value = data;
  Toast.clear();
}
// 实名认证
const onauthRealName = async () => {
  const { idCardNumber, address, realName } = cardInfo.value;

  if (!selectStatus.value) {
    Toast('请勾选协议');
    return;
  }
  if (idCardNumber.length !== 18) {
    Toast('请输入18位身份证号');
    return;
  }
  if (!realName) {
    Toast('请输入真实姓名');
    return;
  }
  await API.authRealName({
    certNo: idCardNumber,
    memberName: realName,
    certType: 1,
    handCardId: 0,
    idCardAddress: address,
    idPhotoConUrl: props.reverseImgPath.watermarkUrl,
    idPhotoFrontUrl: props.frontImgPathParams.watermarkUrl,
  });
  verifiedJump(router, () => {
    store.dispatch('setCertification');
    router.push('/my');
  });
};
const onselect = () => {
  selectStatus.value = !selectStatus.value;
};
const jump = (url) => {
  window.open(url);
};
/** 暴露方法和值 */
defineExpose({
  onshow,
});
</script>
<style lang="scss" scoped>
.popup {
  padding:0 24px;
  &-title {
    font-size: 36px;
    text-align: center;
    padding-top: 30px;
  }
  &-p {
    height: 100px;
    border-bottom: 1px solid #e5e5e5;
    input {
      height: 96px;
      border: none;
      text-align: right;
    }
  }
  &-img {
    height: 212px;
    margin: 30px 0 44px 0;
    > div {
      height: 100%;
      &:first-child {
        margin-right: 22px
      }
      >img {
        @include img;
      }
    }
  }
  &-protocol {
    .protocol-check {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #666;
      position: relative;
      top: -2px;
      margin-right: 10px;
    }
    .check {
      width: 21px;
      height: 21px;
      position: relative;
      top: -2px;
      margin-right: 10px;
      background: url("https://file.dingxiangzu.com/alipay/free-event/bill-check.png") no-repeat;
      background-size: 100%;
      border: none
    }
  }
  .submit {
    @include submit;
    position: fixed;
    width: 98%;
    bottom: 60px;
    left: 50%;
    margin-left: -49%;
  }
}
</style>
